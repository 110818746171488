:root {
  --desktop-alert: hidden;
  --desktop-loading: hidden;
}

.header-container {
  display: flex;
  align-items: center;
  padding-top: 2.5rem;

  .desktop-alert-container,
  .desktop-loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(11, 143, 207, 1) 0%,
      rgb(46, 175, 228) 100%
    );
    background-attachment: fixed;
    z-index: 70;
    visibility: var(--desktop-alert);

    .desktop-alert-title {
      position: absolute;
      font-size: 3rem;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-style: italic;
      line-height: 1;
      padding: 2rem 2rem 2rem 2rem;
      box-sizing: content-box;
      border: solid 0.9rem white;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.22);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .desktop-loader-container {
    visibility: var(--desktop-loading);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .header-data-container {
    width: 100%;
    text-decoration: none;

    .logo {
      position: relative;
      height: 7rem;
      z-index: 45;
    }
  }
}
.desktop-alert-long {
  .desktop-alert-container {
    .desktop-alert-title {
      box-shadow: none !important;
      border: none;
      left: 10%;
      right: 10%;
      transform: translateX(0) translateY(-50%);
      white-space: pre-wrap;
    }
  }
}
