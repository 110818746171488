.page-form {
  width: 80%;

  .page-title {
    margin-top: 0;
    font-size: 3.2rem;
    line-height: 3rem;
    letter-spacing: -0.13rem;
  }

  .page-sub-title {
    font-size: 1.9rem;
    letter-spacing: -0.13rem;
    font-weight: 600;
    font-style: italic;
    text-transform: uppercase;
  }

  .final-image {
    height: auto;
    border: solid 0.1rem white;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 14rem;
    pointer-events: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.22);
  }

  .your-email {
    font-size: 2.2rem;
    letter-spacing: -0.13rem;
    font-weight: 600;
    font-style: italic;
    text-transform: uppercase;
  }

  form {
    width: 100%;
  }

  .form-input {
    width: 100%;
    margin: 0.5rem 0 2rem;
    padding: 0.37rem 1rem;
    color: #737373;
    font-size: 1.5rem;
    font-weight: 300;
    border-radius: 0.6rem;
    background: white;
    border: none;
  }

  .check-header {
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.35rem;
    line-height: 1.3rem;
    &.text-glow {
      text-shadow: 0px 0px 5px rgb(255 255 255 / 0.5),
        0 0px 5px rgb(255 255 255 / 0.5);
    }
  }

  .check-group {
    max-width: 62vw;
    margin: 1rem 0 0 0;
    .checkbox {
      margin: 1rem 0 0 0;
    }
  }

  button {
    font-size: 2.2rem;
    padding: 0.5rem;
  }

  &.page-form-email {
    .page-title {
      padding: 0 1.1rem;
    }
  }

  .privacy {
    margin: 2rem 0 15px 0;
    a {
      text-decoration: underline;
      font-style: italic;
      font-size: 1.2rem;
    }
  }

  .submit-mar {
    margin-top: 1.5rem;
  }
}

.info-title-glow {
  text-shadow: 0px 0px 10px rgb(255 255 255 / 0.4),
    0 0px 10px rgb(255 255 255 / 0.4);
}
