@font-face {
  font-family: 'AATAleckCd';
  font-weight: bold;
  font-style: italic;
  src: local('AATAleckCd'),
    url(../assets/fonts/ATTAleckCd_W_BdIt.woff2) format('woff2');
}

@font-face {
  font-family: 'AATAleckCd';
  font-weight: 900;
  font-style: italic;
  src: local('AATAleckCd'),
    url(../assets/fonts/ATTAleckCd_W_BlkIt.woff2) format('woff2');
}

@font-face {
  font-family: 'AATAleckCd';
  font-style: italic;
  src: local('AATAleckCd'),
    url(../assets/fonts/ATTAleckCd_W_It.woff2) format('woff2');
}

@font-face {
  font-family: 'AATAleckCd';
  font-weight: 300;
  src: local('AATAleckCd'),
    url(../assets/fonts/ATTAleckCd_W_Rg.woff2) format('woff2');
}

@font-face {
  font-family: 'AATAleckCd';
  src: local('AATAleckCd'),
    url(../assets/fonts/ATTAleckCd_W_Md.woff2) format('woff2');
}
