.instruction-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.instruction-text {
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1;
  width: 30rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.position-img {
  width: 30rem;
  object-fit: contain;
}
