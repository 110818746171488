.footer {
  padding-bottom: 1.9rem;
  z-index: 70;

  .footer-image {
    height: 0.96rem;
  }
}

.desktop-alert-on {
  .footer {
    position: fixed !important;
    bottom: 1.9rem;
  }
}
