.welcome-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .warning {
    padding: 0.4rem;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }
}

.welcome-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.welcome-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 9rem;
  margin-top: 6vh;
}

.welcome-text {
  font-size: 1.6rem;
  font-weight: lighter;
  padding-bottom: 1vh;
  text-transform: uppercase;
  width: 90%;
  line-height: 1.2;
  opacity: 0.7;
  white-space: pre-wrap;
}

.welcome-link-button {
  border: solid 0px transparent;
  text-decoration: underline;
  font-style: italic;
  font-size: 1.2rem;
  margin: 2rem 0 15px 0;
}

.team-img {
  height: 30rem;
  object-fit: contain;
}

.title-glow {
  text-shadow: 0px 0px 10px rgb(255 255 255 / 0.6),
    0 0px 10px rgb(255 255 255 / 0.65);
}

.page-title-bold {
  font-size: 5rem;
}
