.players-page-root {
  margin-bottom: auto;
  margin-top: 1rem;
}

.players-container {
  position: absolute;
  width: 85%;
  left: 0;
  z-index: 30;
}

.page-select {
  font-size: 2rem;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 2px;
}

.page-five-players {
  font-size: 3.5rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  text-shadow: 0 0 2px #fff;
}

.page-popup-container {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(11, 143, 207, 1) 0%,
    rgb(46, 175, 228) 100%
  );
  background-attachment: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 42;

  .page-popup {
    position: relative;
    width: 100%;
    max-width: 25rem;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    .page-popup-message {
      position: relative;
      font-weight: 300;
      font-style: italic;
      margin: 15rem 0 5.6rem 0;
      font-size: 2.5rem;
      line-height: 3.7rem;
      letter-spacing: -0.07rem;
      max-width: 22rem;
      left: 50%;
      -moz-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

.button-mar {
  margin-top: 1.7rem;
}

.action-button {
  width: 70%;
}

.submit-button {
  background-color: white;
  color: #0b8fcf;
}

.players-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 20;

  .player {
    width: 23%;
    margin-top: 0.7rem;
    margin-right: 1rem;
    transition: opacity 0.2s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    .player-img-wrapper {
      position: relative;
      justify-content: center;
      .player-img {
        height: 7rem;
        z-index: 21;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        transition-property: transform;
        transition-duration: 0.3s;
      }
      &:before {
        width: 4.45rem;
        height: 4.45rem;
        border: 0.15rem solid white;
        border-radius: 50%;
        transform: scaleX(1) scaleY(1);
      }
      &:after {
        background-image: url('../../assets/img/star.png');
        width: 7rem;
        height: 7rem;
        transform: scaleX(0.0001) scaleY(0.0001);
      }
    }
    .player-first-name,
    .player-last-name {
      font-size: 1rem;
      font-style: italic;
      text-transform: uppercase;
    }
    .player-last-name {
      font-weight: bold;
    }
    &.player-selected {
      .player-img-wrapper {
        &:before {
          transform: scaleX(0.0001) scaleY(0.0001);
        }
        &:after {
          transform: scaleX(1) scaleY(1);
        }
      }
    }
    &:nth-child(3),
    &:last-child {
      margin-right: 0;
    }
  }
  &.all-selected {
    .player:not(.player-selected) {
      opacity: 0.5;
    }
  }
}

.top-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.stars-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.star-top,
.star-high,
.star-middle,
.star-low,
.star-bottom {
  position: absolute;
  height: 9rem;
  right: 0rem;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.star-top {
  top: 20%;
}

.star-high {
  top: 35%;
}

.star-middle {
  top: 50%;
}

.star-low {
  top: 65%;
}

.star-bottom {
  top: 80%;
}
