.camera-select-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.camera-type-page-header {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 3rem;
}

.camera-type-button,
.camera-type-button-active {
  width: 100%;
  border: solid white 2px;
  border-radius: 15px;
  background: linear-gradient(#ffffff50, #00000000, #00000000, #ffffff50);
  margin-bottom: 2rem;

  span {
    color: white;
  }
}

.camera-type-button-active {
  background-color: white;

  span {
    color: rgba(11, 143, 207, 1);
  }
}

.camera-type-header {
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
}

.camera-type-description {
  font-size: 1.1rem;
  text-transform: uppercase;
  white-space: pre-wrap;
}

.camera-type-button-container {
  padding-top: 0.2rem;
  padding-bottom: 1.5rem;
}

.camera-type-button-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
}

.camera-type-person-img {
  width: 2.5rem;
  object-fit: contain;
}

.camera-type-arrow-container {
  width: 70%;
  padding: 0rem 1.5rem;
}

.camera-type-arrow-img {
  width: 100%;
  padding: 1rem 0rem;
}

.select-title-glow {
  text-shadow: 0px 0px 10px rgb(255 255 255 / 0.4),
    0 0px 10px rgb(255 255 255 / 0.4);
}
