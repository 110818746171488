.page-help {
  width: 100%;

  .steps-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.8rem;

    .steps-item {
      display: flex;
      position: relative;
      margin-bottom: 1rem;

      .steps-item-text-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 4.2rem 1.1rem 3.7rem;

        .steps-item-text {
          display: flex;
          flex-direction: column;
          text-align: left;

          .steps-item-header,
          .steps-item-info {
            font-style: italic;
          }

          .steps-item-header {
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 3.2rem;
          }

          .steps-item-info {
            font-size: 1.2rem;
            max-width: 12rem;
          }
        }
      }

      .steps-image {
        width: 3.3rem;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background: url(../../assets/img/bracket.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }

      &:after {
        transform: rotate(180deg);
        right: 0;
      }
    }
  }

  .button1 {
    margin-top: 2rem;
  }
}
