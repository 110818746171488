.config-page {
  width: 100%;
  flex: 1;
  padding: 2rem 1rem 2rem 1rem;

  .player-img-wrapper {
    position: relative;
    justify-content: center;

    .player-img {
      height: 10rem;
      z-index: 21;
    }
    &:after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      transition-property: transform;
      transition-duration: 0.3s;
      background-image: url('../../assets/img/star.png');
      width: 10rem;
      height: 10rem;
    }
  }

  form {
    text-align: left;
    font-size: 1.2rem;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 0.3rem;

      span {
        width: 15rem;
      }
    }

    select,
    input {
      width: 100%;
      background-color: transparent;
      border: 1px solid white;
      border-radius: 4px;
      font-size: 1.2rem;
      padding: 0.3rem 0.5rem;

      option {
        background-color: #0b8fcf;
      }
    }

    input {
      &::placeholder {
        color: white;
        opacity: 0.7;
        font-style: italic;
      }
    }

    fieldset {
      border: 2px solid white;
      border-radius: 4px;
    }

    button[type='submit'] {
      margin-top: 1.5rem;
    }

    button[type='button'] {
      background: transparent;
      border: 1px solid white;
      border-radius: 4px;
      padding: 0.35rem;
      margin-left: 0.3rem;
      width: 6rem;
    }
  }

  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    visibility: visible !important;
    pointer-events: none;
  }
}

.config-mode {
  overflow: auto;
  padding: 2rem 0;

  .header-container {
    padding-top: 0;
    .header-data-container .logo {
      height: 4rem;
    }
  }

  .footer {
    display: none;
  }
}
