.lang-switch {
  width: auto;
  margin-top: 1.5rem;
  padding: 0;
  font-size: 0.9rem;
  font-style: italic;
  border: none;
  border-bottom: solid 1px white;
  border-radius: 0;
}
