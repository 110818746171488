@import './styles/fonts.scss';
@import './styles/constants.scss';

:root {
  --page-opacity: 0;
  --font-size: 1.6vh;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(11, 143, 207, 1) 0%,
    rgb(46, 175, 228) 100%
  );
  background-attachment: fixed;
  min-height: calc(var(--vh, 1vh) * 100);
  touch-action: manipulation;
  overflow: hidden;
}

html {
  font-size: calc(var(--font-size, 1vh) * 1.6);
  font-family: AATAleckCd;
  transition: font-size 0.2s;
}

#root {
  height: 100%;
  max-width: 40rem;
  width: 100%;
  opacity: var(--page-opacity);
  transition: opacity 0.5s;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/stars.png');
    background-repeat: no-repeat;
    opacity: 0.5;
    pointer-events: none;
  }
}

main {
  height: 100%;
  width: 100%;
  text-align: center;
}

* {
  touch-action: manipulation;
  box-sizing: border-box;
  color: white;
  font-family: AATAleckCd;
  outline: none;
}

:not(input) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.center-page {
  padding: 0rem 2.6rem 0rem 2.6rem;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

video::shadow::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.page-title,
.page-title-bold {
  font-size: 4.5rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  margin-top: 2rem;
}

.page-title-bold {
  font-size: 5.6rem;
}

.button1,
.button2,
.button3,
.button-small {
  background-image: url(../assets/img/btnBack1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px;
  border-radius: 0.6rem;
  width: 100%;
  padding: 0.4rem;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  &:active {
    background-color: white;
    color: #0c90d0;
  }
}

.button2 {
  background-image: url(../assets/img/btnBack2.png);
}

.button3 {
  background-image: url(../assets/img/btnBack3.png);
}

.button-small {
  background-image: url(../assets/img/btnBackSmall.png);
  font-size: 1.3rem;
}

button {
  background: transparent;
}

button:disabled {
  opacity: 0.5;
}

.link-button {
  font-size: 1rem;
  text-transform: uppercase;
  border-style: solid;
  border-color: transparent;
  border-width: 0px 0px 0px 0px;
  padding: 0px;
  font-weight: 300;
}

.mode-switch {
  width: auto;
  padding: 0 0.15rem;
  font-size: 1rem;
  font-style: italic;
  border: none;
  border-bottom: solid white;
  border-radius: 0;
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.text-glow {
  text-shadow: 0px 0px 10px rgb(255 255 255 / 0.25),
    0 0px 10px rgb(255 255 255 / 0.25);
}

.text-glow-black {
  text-shadow: 0px 0px 10px rgb(0 0 0 / 0.25), 0 0px 10px rgb(0 0 0 / 0.25);
}

.align-cc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.player-video-container {
  position: fixed;
  right: 0px;
  width: 80%;
  height: 80%;
  object-fit: cover;
  visibility: hidden;
}

.video-container {
  position: absolute;
  right: 0px;
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.top-video,
.high-video,
.middle-video,
.low-video,
.bottom-video {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  visibility: visible !important;
  pointer-events: none;
}

.top-video {
  top: -32%;
  z-index: 11;
}
.high-video {
  top: -15%;
  z-index: 12;
}
.middle-video {
  top: 2%;
  z-index: 13;
}
.low-video {
  top: 18%;
  z-index: 14;
}
.bottom-video {
  top: 37%;
  z-index: 15;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.landscape-alert {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0b8fcf;
  z-index: 50;
  visibility: hidden;

  .landscape-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    .alert-title {
      font-size: 3rem;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-style: italic;
    }

    .alert-sub-title {
      font-size: 2.3rem;
      text-align: center;
      font-style: italic;
    }
  }
}

@media screen and (orientation: landscape) {
  .landscape-alert {
    visibility: visible !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (orientation: landscape) {
  html {
    font-size: 1.3vw;
  }
  body {
    overflow: auto;
  }
  #root {
    height: auto;
  }
  .footer {
    margin-top: 3rem;
  }
}
