.thanks-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 3rem;
}

.thanks-header {
  font-style: italic;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.1em;
  text-transform: uppercase;
}

.thanks-tag-social {
  font-style: italic;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.1rem;
  margin-top: 1rem;
}

.thanks-details {
  font-size: 2rem;
  line-height: 2rem;
  margin-top: 20px;
}

.another-photo-container {
  width: 100%;
  margin-bottom: 1rem;
}

.final-image {
  height: auto;
  border: solid 0.3rem white;
  margin-bottom: 3rem;
  width: 20rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.22);
  pointer-events: none;
}

.final-image-container {
  margin-top: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.another-photo-button {
  width: 17.5rem;
}

.link-buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 2rem 15px 2rem;
  height: 4rem;
  justify-content: space-between;
}

.heading-glow {
  text-shadow: 0px 0px 10px rgb(255 255 255 / 0.4),
    0 0px 10px rgb(255 255 255 / 0.4);
}
