.camera-container {
  position: relative;
  width: calc(100vh * 0.565);
  height: 100%;
}

:root {
  --ball-position: 50%;
  --level-visible: visible;
}

.video {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#otherVideos {
  .video {
    opacity: 0;

    &.video-playing {
      opacity: 1;
    }
  }
}

.top-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (max-aspect-ratio: 9/16) {
  .camera-container {
    width: 100%;
    height: calc(100vw * 1.77);
  }
}

.page-title-text {
  font-size: 6rem;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  margin-top: 2rem;
  letter-spacing: -0.2rem;
}

.bottom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 4rem;
  position: relative;
}

.bottom-buttons {
  width: 100%;
}

.place-img {
  width: 80%;
  object-fit: contain;
}

.back-button {
  width: 5rem;
  float: left;
  margin: 0.8rem 0rem;
  border: solid white 1px;
  padding: 0.15rem;
  border-radius: 4px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 900;
}

.permission-text {
  margin-top: 10rem;
  display: block;
  font-size: 2rem;
}

.gyroscope-container {
  position: absolute;
  height: 12.7rem;
  width: 2.4rem;
  background-color: #ffffff80;
  border: solid 2px white;
  border-radius: 0.8rem;
  top: 35%;
  left: 10px;
  z-index: 20;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gyroscope-center {
  position: absolute;
  height: 2.1rem;
  width: 2rem;
  background-color: #127ba880;
  top: 50%;
  left: 0px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gyroscope-ball {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  border-radius: 0.75rem;
  top: var(--ball-position);
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.level-phone-container {
  position: absolute;
  display: flex;
  top: 35%;
  left: 4.1rem;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
  background-color: black;
  visibility: var(--level-visible);
}

.left-vector {
  height: 3rem;
  width: 3.4rem;
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.left-vector-title {
  position: absolute;
  width: 31rem;
  font-size: 2.9rem;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  text-align: left;
  line-height: 1;
  top: 50%;
  left: 3.9rem;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.loading-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.loading-item {
  width: 130px;
  height: 130px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flash-view {
  opacity: 0;
  background: white;
  pointer-events: none;
  transition: opacity 0.25s;
}

.countdown-label {
  font-size: 7rem;
  font-weight: 900;
  font-style: italic;
}

.stand-here-container {
  position: absolute;
  top: -2.3rem;
  visibility: var(--level-visible);

  p {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 900;
  }
  img {
    width: 25%;
  }
}

.title-glow {
  text-shadow: 0px 0px 10px rgb(255 255 255 / 0.4),
    0 0px 10px rgb(255 255 255 / 0.4);
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
